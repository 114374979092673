<template>
  <section class="Menu">
    <div class="menu-button" @click="goBack()">
      <transition leave-active-class="animate__animated animate__bounceOut">
        <div v-if="show2">
          <MenuBurger />
        </div>
      </transition>
      <transition name="roll">
        <div v-if="show">
          <Cross />
        </div>
      </transition>
    </div>

    <div class="menu-block vertical-center">
      <router-link class="menu-link" to="/offer"
        ><span class="transition-one">OFERTA</span>
        <span class="transition-one offer">OFERTA</span></router-link
      >
      <router-link class="menu-link" to="/productions"
        ><span class="transition-one">REALIZACJE</span>
        <span class="transition-one productions">REALIZACJE</span></router-link
      >
      <router-link class="menu-link" to="/partnership"
        ><span class="transition-one">WSPÓŁPRACA</span>
        <span class="transition-one partnership">WSPÓŁPRACA</span></router-link
      >
      <router-link class="menu-link" to="/team"
        ><span class="transition-one">ZESPÓŁ</span>
        <span class="transition-one team">ZESPÓŁ</span></router-link
      >
      <router-link class="menu-link" to="/contact"
        ><span class="transition-one">KONTAKT</span>
        <span class="transition-one contact">KONTAKT</span></router-link
      >
    </div>
    <div class="menu-footer">
      <a href="/"><img src="../assets/logo.png" class="footer-badge"/></a>
    </div>
  </section>
</template>

<script>
import MenuBurger from "@/components/MenuBurger.vue";
import Cross from "@/components/Cross.vue";
export default {
  name: "Menu",
  components: {
    MenuBurger,
    Cross
  },
  data() {
    return {
      show: false,
      show2: true
    };
  },
  methods: {
    goBack: function() {
      this.$router.back();
    }
  },
  beforeMount: function() {
    setTimeout(() => (this.show2 = false), 600);
    setTimeout(() => (this.show = true), 1610);
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/Menu/_Menu.scss";
@import "@/assets/styles/Menu/responsive/xlarge_D.scss";
@import "@/assets/styles/Menu/responsive/large_D.scss";
@import "@/assets/styles/Menu/responsive/medium_D.scss";
@import "@/assets/styles/Menu/responsive/small_D.scss";
@import "@/assets/styles/Menu/responsive/xsmall_d.scss";
.roll-enter-active {
  animation: roll-in 1s;
}
.roll-leave-active {
  animation: roll-in 1s reverse;
}
@keyframes roll-in {
  0% {
    transform: scale(0) rotateZ(0deg) translateX(-2px);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotateZ(360deg) translateX(0px);
    opacity: 1;
  }
}
</style>
