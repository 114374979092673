<template>
  <div class="cross">
    <div class="cross-line-st"></div>
    <div class="cross-line-nd"></div>
  </div>
</template>

<script>
export default {
  name: "Cross"
};
</script>

<style lang="scss">
@import "@/assets/styles/_global.scss";
.cross {
  padding-top: 10px;
  height: 30px;
  width: 50px;
  z-index: 1;
  position: relative;
  transition: all 0.5s;
}
.cross:hover {
  cursor: pointer;
}
.cross-line-st,
.cross-line-nd {
  background-color: $accentcolor;
  height: 3px;
  width: 35px;
}
.cross-line-st {
  transform: rotate(45deg);
  transition: all 0.5s;
}
.cross-line-nd {
  transform: rotate(-45deg);
  margin-top: -3px;
  transition: all 0.5s;
}
.cross:hover {
  padding-top: 13px;
  padding-left: 6px;
  .cross-line-st {
    width: 40px;
    transform: rotate(35deg);
  }
  .cross-line-nd {
    width: 40px;
    transform: rotate(-35deg);
  }
}
</style>
