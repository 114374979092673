<template>
  <div class="Menuu page-view">
    <Menuu />
  </div>
</template>
<style></style>
<script>
import Menuu from "@/components/Menu.vue";

export default {
  name: "Menu",
  components: {
    Menuu
  },
  methods: {
    scroll: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  beforeMount: function() {
    setTimeout(() => this.scroll(), 100);
  }
};
</script>
